<template>
    <div>
        <v-row>
            <v-col cols="12" v-if="local.items != undefined && local.items != null">
                <v-list avatar two-line class="pt-0 mt-0" v-if="!totalsonly">
                    <template v-for="(item, ix) in local.items">
                        <v-list-item :key="item.productId" 
                            :class="getItemClass(item)" 
                            :three-line="assignation || inspection">
                            <v-list-item-avatar v-if="item.imgSrc != undefined && item.imgSrc != null">
                                <v-img :src="item.imgSrc" />
                            </v-list-item-avatar>
                            <v-list-item-content :style="productItemColor(item.productId)">
                                <div>{{ itemName(item) }} <v-icon small @click="showImage(item.imgSrc)" v-if="item.imgSrc != undefined && item.imgSrc != null">mdi-camera</v-icon></div>
                                <div class="caption grey--text">{{ item.category }}</div>
                                
                                <div class="caption red--text" v-if="needTailoring(item)">
                                    <v-chip color="error" x-small label>Need tailoring</v-chip>
                                </div>
                            </v-list-item-content>
                            <v-list-item-action :style="productItemColor(item.productId)">
                                <div class="grey--text">
                                    <span class="grey--text text--lighten-1 caption" style="text-decoration:line-through" v-if="getCurrentStyle(item) != itemStyle(item)">{{ itemStyle(item) }}</span>
                                    {{ getCurrentStyle(item) }}
                                </div>
                                <div class="grey--text" v-html="getCurrentSize(item)"></div>
                                <div class="caption grey--text" v-if="getBarcode(item) != ''">{{ getBarcode(item) }}</div>
                                <!-- <div class="caption grey--text" v-if="assignation && isAssigned(item)">{{ getBarcode(item) }}</div>
                                <div class="caption grey--text" v-else-if="inspection && isAssigned(item)">{{ getBarcode(item) }}</div> -->
                            </v-list-item-action>
                        </v-list-item>
                        <!-- <v-btn depressed block color="primary" small @click="$emit('altitem', item)"
                            v-if="tailoring && ( item.categoryId == 'C' || item.categoryId == 'P' )" :key="'btn' + item.productId">
                            Register alteration
                        </v-btn> -->

                        <package-changes :item="item" :changes="changes" :class="getItemClass(item)" :assigned="isAssigned( item )" :key="ix" />

                        <!-- <v-timeline dense :key="`tm-${item.id}`" v-if="ix == 0">
                            <v-timeline-item color="grey" small>
                                <v-list-item dense style="background-color: #eee; border-radius: 8px; max-width: 400px;">
                                    <v-list-item-avatar>
                                        <v-img :src="item.imgSrc" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>LFA Tux Navy Notch SB-2B Slim Tux</v-list-item-title>
                                        <v-list-item-subtitle>Coat</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div class="grey--text">
                                            200
                                        </div>
                                        <div class="grey--text">38R</div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-timeline-item>
                        </v-timeline> -->
                    </template>
                </v-list>

                <!-- Total -->
                <v-divider inset class="mr-5 mb-2" v-if="(showTotals || totalsonly) && local.items.length > 0 && !itemsonly"></v-divider>
                <div v-if="(showTotals || totalsonly) && local.items.length > 0 && !itemsonly" class="text-right ma-3">
                    
                    <v-row no-gutters>
                        <!-- subtotal -->
                        <v-col cols="9">Subtotal</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( subtotal ) }}</v-col>

                        <!-- extra items -->
                        <v-col cols="9" v-if="extraItems.length > 0">
                            Extra Items
                            <v-row no-gutters>
                                <template v-for="xi in extraItems">
                                    <v-col cols="10" offset="2" :key="xi.categoryId">
                                        <span class="caption grey--text">{{ xi.category }} x {{ xi.qty }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="extraItems.length > 0">
                            {{ formatMoney( totalExtraItems ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="xi in extraItems">
                                    <v-col cols="12" :key="xi.categoryId">
                                        <span class="caption text-right">{{ formatMoney( xi.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <!-- damage waiver -->
                        <v-col cols="9">Damage Waiver</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( damageWaiver ) }}</v-col>

                        <!-- delivery -->
                        <v-col cols="9" v-if="showDelivery && pickupLocation != null">
                            Delivery @ {{ pickupLocation.name }}
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="showDelivery && pickupLocation != null">
                            {{ formatMoney( pickupLocation.charge ) }}
                        </v-col>

                        <!--  charges -->
                        <v-col cols="9" v-if="local.charges != null && local.charges.length > 0">
                            Extra Charge
                            <v-row no-gutters>
                                <template v-for="c in local.charges">
                                    <v-col cols="10" offset="2" :key="c.code">
                                        <span class="caption grey--text">{{ c.code }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="local.charges != null && local.charges.length > 0">
                            {{ formatMoney( totalExtraCharge ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="c in local.charges">
                                    <v-col cols="12" :key="c.code">
                                        <span class="caption text-right">{{ formatMoney( c.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <!-- promo codes -->
                        <v-col cols="9" v-if="local.promoCodes != null && local.promoCodes.length > 0">
                            Promo Codes
                            <v-row no-gutters>
                                <template v-for="pc in local.promoCodes">
                                    <v-col cols="10" offset="2" :key="pc.code">
                                        <span class="caption grey--text">{{ pc.code }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="text-right" v-if="local.promoCodes != null && local.promoCodes.length > 0">
                            - {{ formatMoney( totalPromoDiscount ) }}
                            <v-row no-gutters style="border-right:2px solid grey;" class="pr-2 mr-2">
                                <template v-for="pc in local.promoCodes">
                                    <v-col cols="12" :key="pc.code">
                                        <span class="caption text-right">- {{ formatMoney( pc.amount ) }}</span>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>

                        <v-col cols="9">Tax</v-col>
                        <v-col cols="3" class="text-right">{{ formatMoney( tax ) }}</v-col>

                        <v-col cols="9" class="title font-weight-light mt-2">Total</v-col>
                        <v-col cols="3" class="title font-weight-light mt-2 text-right" :style="noChargeApplied ? 'text-decoration: line-through;' : ''">{{ formatMoney( total ) }}</v-col>

                        <v-col cols="9" class="title font-weight-light mt-2" v-if="noChargeApplied">
                            No Charge: <span class="body-1">{{ this.noCharge.noCharge }}</span></v-col>
                        <v-col cols="3" class="title font-weight-light mt-2 text-right" v-if="noChargeApplied">{{ formatMoney( 0 ) }}</v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="imageDialog" width="300">
            <v-card>
                <v-card-text class="pa-3">
                    <v-img :src="dialogImgSrc" v-if="dialogImgSrc != undefined && dialogImgSrc != null"></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PackageChanges from '@/components/PackageChanges.vue';
import { _st } from '@/softech';

export default {
    props: {
        value               : { type: Object, default: () => { return {} } },
        assignation         : { type: Boolean, default: false },
        tailoring           : { type: Boolean, default: false },
        inspection          : { type: Boolean, default: false },
        showTotals          : { type: Boolean, default: true },
        totalsonly          : { type: Boolean, default: false },
        itemsonly           : { type: Boolean, default: false },
        showDelivery        : { type: Boolean, default: false },
        pickupLocation      : { type: Object, default: null },
        noCharge            : { type: Object, default: null },
        unavailableItems    : { type: Array, default: () => { return []; } },
        itemsAssigned       : { type: Array, default: () => ([]) },
        itemsScanned        : { type: Array, default: () => ([]) },
        storeId             : { type: [String, Number], default: 0 },
        changes             : { type: Array, default: () => ([]) },
    },
    data() {
        return {
            local               : this.value,

            imageDialog         : false,
            dialogImgSrc        : null,
            
            threeLine           : this.assignation,

            items2Barcode       : [],
            itemsScanned2Barcode: [],
        }
    },
    watch: {
        value(v) {
            this.local = v;

            if( this.assignation || this.inspection )
                this.assignItems();
            if( this.inspection )
                this.assignScannedItems();
        },
        itemsAssigned() {
            this.assignItems();
        },
        itemsScanned() {
            this.assignItems();
            this.assignScannedItems();
        }
    },
    computed: {
        componentReady() {
            if( _st.isNU( this.value ) )
                return false;
            
            return true;
        },
        coatExist() {
            if( !this.componentReady )
                return false;
            if( this.local.items == undefined || this.local.items == null )
                return false;

            return this.local.items.filter(i => {
                return this.itemCategoryId( i ) == 'C';
            }).length > 0;
        },
        localSubtotal() {
            if( !this.componentReady )
                return 0;
            if( this.local.items == undefined )
                return 0;

            return this.mxSubtotal( this.local.items );
        },
        subtotal() {
            if( !this.componentReady )
                return 0;
            if( this.local.items == undefined )
                return 0;
            if( this.local.subtotalOverwrite != null && parseFloat(this.local.subtotalOverwrite) > 0 )
                return parseFloat(this.local.subtotalOverwrite);

            let subtotal = 0;

            this.local.items.forEach(i => {

                if( this.coatExist ) {
                    if( this.itemCategoryId( i ) == 'C' || this.itemCategoryId( i ) == 'SH' || this.itemCategoryId( i ) == 'SO' ) 
                        subtotal += parseFloat(i.price);
                }
                else
                    subtotal += parseFloat(i.price); 
            });

            return subtotal;
        },
        damageWaiver() {
            if( !this.componentReady )
                return 0;
            if( this.local.items == undefined )
                return 0;

            return this.local.items.length > 0 ? 10 : 0;
        },
        tax() {
            let store = this.stores.find(s => s.id == this.storeId);
            let tax = _st.isNUE( store ) ? 0.115 : store.tax;

            return Math.round(( 
                this.subtotal 
                + this.damageWaiver 
                + this.totalExtraItems 
                + ( this.showDelivery && this.pickupLocation != null ? parseFloat( this.pickupLocation.charge ) : 0 ) 
                + this.totalExtraCharge - this.totalPromoDiscount 
            ) * tax * 100 ) / 100; 
        },
        totalPromoDiscount() {
            if( !this.componentReady )
                return 0;
            if( this.local.items == undefined )
                return 0;

            let total = 0;
            if( this.local.promoCodes != undefined ) {
                this.local.promoCodes.forEach(p => { 
                    total += parseFloat(p.amount);
                });
            }

            return total;
        },
        totalExtraCharge() {
            if( !this.componentReady )
                return 0;
            if( this.local.charges == null )
                return 0;

            let total = 0;
            this.local.charges.forEach(c => {
                total += parseFloat( c.amount );
            });

            return total;
        },
        extraItems() {
            if( !this.componentReady )
                return [];
            if( this.local.items == undefined || this.local.items == null )
                return [];

            let currentItems = [];
            let xtraItems = [];
            
            this.local.items.forEach(p => {
                // let product = this.products.find(ps => { return ps.id == p.id; });
                // if( product == undefined )
                //     return;

                let categoryId = this.itemCategoryId( p );
                if( currentItems.filter(i => { return i.categoryId == categoryId } ).length == 0 ) {
                    currentItems.push({
                        categoryId: categoryId,
                        category: p.category,
                        qty: 1
                    });
                }
                else {
                    let item = currentItems.find(i => {
                        return i.categoryId == categoryId;
                    });

                    item.qty++;
                }
            });

            currentItems.forEach(ci => {
                if( ci.qty > 1 ) {
                    let ei = this.extraItemsObj.find(eic => { return eic.categoryId == ci.categoryId });
                    let amount = _st.isNUE( ei ) ? 0 : ei.amount;
                    xtraItems.push({
                        categoryId: ci.categoryId,
                        category: ci.category,
                        qty: ci.qty - 1,
                        amount: (ci.qty - 1) * amount
                    });
                }
            });

            return xtraItems;
        },
        totalExtraItems() {
            if( !this.componentReady )
                return 0;

            let total = 0;
            this.extraItems.forEach(xi => {
                total += parseFloat(xi.amount);
            });

            return total;
        },
        total() {
            return this.subtotal + this.damageWaiver  + this.totalExtraItems + ( this.showDelivery && this.pickupLocation != null ? parseFloat( this.pickupLocation.charge ) : 0 ) + this.totalExtraCharge - this.totalPromoDiscount + this.tax;
        },
        noChargeApplied() {
            return !_st.isNUE( this.noCharge );
        },
        unmatchedItems() {
            let unmatched = [];

            this.itemsAssigned.forEach(item => {
                if( !_st.extractProp( this.items2Barcode, 'barcode' ).includes( item.barcode ) )
                    unmatched.push( item );
            });

            return unmatched;
        },
        unscannedItems() {
            let unscanned = [];

            this.itemsScanned.forEach(item => {
                if( !_st.extractProp( this.itemsScanned2Barcode, 'barcode' ).includes( item.barcode ) )
                    unscanned.push( item );
            });

            return unscanned;
        }
    },
    mounted() {
        if( this.assignation || this.inspection )
            this.assignItems();
        if( this.inspection )
            this.assignScannedItems();
    },
    methods: {
        showImage(src) {
            this.dialogImgSrc = src;
            this.imageDialog = true;
        },
        getImgPath( src ) {
            var images = require.context('../assets/images/', false, /\.jpg$|\.JPG$|\.png$|\.PNG$/);
            return images( './' + src );
        },
        getItemClass( item ) {
            if( ( this.assignation && !this.isAssigned(item) && item.hasBarcode ) 
                || ( this.inspection && !this.isScanned(item) && item.hasBarcode ) )
                return 'disabled';
                
            if( this.isScanned( item ) || this.isAssigned( item ) )
                return 'scanned';

            return '';
        },
        productItemColor(pid) {
            if( !this.unavailableItems.includes( pid ) )
                return '';

            return 'color:red;';
        },
        itemName(item) {
            return item.description ?? item.productName;
        },
        itemStyle(item) {
            return item.style ?? item.foreignId;
        },
        itemCategoryId(item) {
            return item.categoryId ?? item.categoryID;
        },
        isAssigned(item) {
            return _st.extractProp( this.items2Barcode, 'itemId' ).includes( item.itemId );
        },
        isScanned(item) {
            return _st.extractProp( this.itemsScanned2Barcode, 'itemId' ).includes( item.itemId );
            // return this.inspection && item.hasBarcode && _st.extractProp( this.itemsScanned, 'categoryId' ).includes( item.categoryId );
        },
        getBarcode(item) {
            if( !this.assignation && !this.tailoring && !this.inspection )
                return '';

            try {
                return this.items2Barcode.find(i => i.itemId == item.itemId).barcode;
                // return this.itemsAssigned.find(i => i.categoryId == item.categoryId).barcode;
            }
            catch(error) {
                return '';
            }
        },
        getCurrentStyle(item) {
            try {
                return this.itemsAssigned.find(i => i.productId == item.productId).style;
                // return this.itemsAssigned.find(i => i.categoryId == item.categoryId).style;
            }
            catch(error) {
                return this.itemStyle(item);
            }
        },
        getCurrentSize(item) {
            try {
                let itemSize = item.size.split(' - ');
                let i = this.itemsAssigned.find(i => i.productId == item.productId);
                // let i = this.itemsAssigned.find(i => i.categoryId == item.categoryId);
                let size = '';

                if( i.categoryId == 'C' || i.categoryId == 'P' )
                    size = `${i.size} - ${itemSize[1]}`;
                else 
                    size = i.size;

                if( itemSize[0] != i.size )
                    return `<span class="grey--text text--lighten-1 caption" style="text-decoration:line-through">${item.size}</span> ${size}`;
                
                return size;
            }
            catch(error) {
                return item.size;
            }
        },
        needTailoring(prod) {
            // if( !this.assignation && !this.tailoring )
            //     return false;

            if( prod.categoryId != 'C' && prod.categoryId != 'P' )
                return false;

            if( !this.isAssigned( prod ) )
                return false;
            
            let size = prod.size.split(' - ')[1];
            let item = this.itemsAssigned.find(i => i.productId == prod.productId);

            if( item === undefined )
                item = this.itemsAssigned.find(i => i.categoryId == prod.categoryId);

            if( parseFloat( size ) != parseFloat( item.altered ) && Math.abs( parseFloat( size ) - parseFloat( item.altered) ) > 0.15 )
                return true;
        },
        assignItems() {
            this.items2Barcode = [];

            // by product id
            this.local.items.forEach(item => {
                // check if is already assigned
                if( _st.extractProp( this.items2Barcode, 'itemId' ).includes( item.itemId ) )
                    return;

                let assigned = item.hasBarcode && _st.extractProp( this.unmatchedItems, 'productId' ).includes( item.productId );
                if( assigned === true ) {
                    this.items2Barcode.push({ itemId: item.itemId, barcode: this.unmatchedItems.find(i => i.productId == item.productId).barcode });
                    return;
                }
            });

            // by category id
            this.local.items.forEach(item => {
                // check if is already assigned
                if( _st.extractProp( this.items2Barcode, 'itemId' ).includes( item.itemId ) )
                    return;

                let cat = this.itemCategoryId( item );

                let assigned = item.hasBarcode && _st.extractProp( this.unmatchedItems, 'categoryId' ).includes( this.itemCategoryId( item ) );
                if( assigned === true ) {
                    this.items2Barcode.push({ itemId: item.itemId, barcode: this.unmatchedItems.find(i => i.categoryId == cat).barcode });
                    return;
                }
            });
        },
        assignScannedItems() {
            // ME QUEDE AQUI. TODAVIA NO ME SALE QUE SE PINTEN LOS ITEMS QUE ESCANEO BASADO EN EL BARCODE ****
            this.itemsScanned2Barcode = [];

            // local.items              : productos dentro del paquete de la orden (tblorderdetails)
            // itemsScanned             : items que el usuario ha escaneado (tblitem)
            // unscannedItems           : items que el usuario ha escaneado pero no se ha asignado a ningún item en la lista (tblitem)
            // itemsScanned2Barcode     : items escaneados y asignados a la lista (array: { order.itemId, barcode })

            // console.log('itemsScanned', this.itemsScanned);
            // console.log('itemsAssigned', this.itemsAssigned);

            // by item id
            this.local.items.forEach(item => {
                if( !item.hasBarcode )
                    return;

                // check if is already assigned
                if( !_st.extractProp( this.items2Barcode, 'itemId' ).includes( item.itemId ) )
                    return;

                // get assigned item if exist
                let matched = this.items2Barcode.find(i => i.itemId == item.itemId);
                if( _st.isNUE( matched ) )
                    return;

                // check if is already scanned
                if( !_st.extractProp( this.unscannedItems, 'barcode' ).includes( matched.barcode ) )
                    return;

                this.itemsScanned2Barcode.push({ itemId: item.itemId, barcode: matched.barcode });
                return;
            });

            // console.log( this.itemsScanned2Barcode );
        }
    },
    components: {
        PackageChanges
    }
}
</script>

<style lang="scss" scoped>
.v-list .disabled .v-list-item__content div:first-child {
    color: rgba(0, 0, 0, 0.38);
}
.v-list .scanned {
    background-color: rgb(216, 245, 216);
}
.v-list-item.scanned:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.v-list-item.scanned:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
</style>
